/* eslint-disable @typescript-eslint/no-floating-promises */
import { Disposition, DocumentType } from "@/graphql/generated";
import GET_DOWNLOAD_URL from "@/graphql/queries/downloadUrl/getDownloadUrl";
import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

export default function useDownloadUrl({
  id,
  type,
  viewType,
}: {
  id?: string;
  type?: DocumentType;
  viewType?: Disposition;
}) {
  const [getDownloadUrl, { loading, error }] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "no-cache",
    variables: {
      objectId: id,
      objectType: type,
      disposition: viewType,
    },
  });

  const downloadUrl = useCallback(
    async ({
      objectId,
      objectType,
      disposition,
    }: {
      objectId?: string;
      objectType: DocumentType;
      disposition?: Disposition;
    }) =>
      getDownloadUrl({
        variables: {
          objectId: objectId || id,
          objectType: objectType || type,
          disposition: disposition || viewType,
        },
      }),
    [getDownloadUrl, id, type, viewType]
  );

  return { getDownloadUrl, downloadUrl, loading, error };
}
