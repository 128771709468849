import React from "react";
import * as Styled from "./styles";
import ToolTip from "../Tooltip";

export type StatusPillProps = {
  color: Styled.StatusPillColor;
  bgColor: Styled.StatusPillColor;
  tooltipText?: string;
} & (
  | { label: string; icon?: React.ReactNode }
  | { label?: string; icon: React.ReactNode }
);

const withTooltip = (
  tooltipText: string | undefined,
  children: React.ReactElement
) =>
  tooltipText ? (
    <ToolTip popperContent={tooltipText}>{children}</ToolTip>
  ) : (
    children
  );

const StatusPill: React.FC<StatusPillProps> = ({
  color,
  bgColor,
  label,
  icon,
  tooltipText,
}) => {
  const pillContent = (
    <Styled.Container
      $size="xs"
      $weight="medium"
      $textColor={color}
      $bgColor={bgColor}
      $iconOnly={!label}
    >
      {icon && <Styled.IconContainer>{icon}</Styled.IconContainer>}
      {label && label}
    </Styled.Container>
  );
  return withTooltip(tooltipText, pillContent);
};

export default StatusPill;
