import styled from "@emotion/styled";
import defaultTheme from "@/styles/themes/default";
import { Body1 } from "@/components/Typography";
import { RenewTheme } from "@/styles/emotion";

export type StatusPillColor = keyof RenewTheme["renewTheme"]["colors"];

export const Container = styled(Body1)<{
  $textColor: StatusPillColor;
  $bgColor: StatusPillColor;
  $iconOnly: boolean;
}>`
  color: ${(props) => defaultTheme.renewTheme.colors[props.$textColor]};
  background-color: ${(props) =>
    defaultTheme.renewTheme.colors[props.$bgColor]};
  display: flex;
  cursor: inherit;
  gap: 3px;
  align-items: center;
  width: 100%;
  max-width: fit-content;
  height: fit-content;
  border-radius: 1rem;
  padding: ${(props) => (props.$iconOnly ? "8px" : "4px 8px")};
  white-space: nowrap;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 10px;
    width: 10px;
  }
`;
